import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="統合失調症における認知機能障害の薬理学的治療" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          第7回 統合失調症における認知機能障害の薬理学的治療
        </h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6314307953112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Eduard Vieta教授(バルセロナ大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>中込和幸先生 (国立精神神経医療研究センター）</span>
            </p>
          </div>
          <Navigation prev="10" next="12" />
          <div className={styles.paragraph}>
            <p>
              統合失調症の認知機能障害に対する薬物療法の有効性と安全性を評価します。また薬物の作用メカニズムを整理し、認知機能障害の治療に向けた新たな薬物開発の現状を学ぶことができます。
            </p>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'統合失調症における認知機能障害の薬理学的治療'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
